<template>
    <v-container  fluid>
    <v-row dense v-if="this.user_roles_list.flat().includes('work_orders_write')">
        <v-col lg="6" offset-lg="3">
                <FormHeader v-bind='editWorkCenterGroupHeader' />
                <v-card>
                <!--New Work Center Group form -->
                <v-form @submit.prevent="updateWorkCenterGroup" class="pa-3" ref="form" v-model="valid">
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    v-model="work_center_group_name"
                                    counter="50"
                                    label="Group Name"
                                    placeholder="Eg: Assembly Line #1..."
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    v-model="work_center_group_number"
                                    label="Group #"
                                    readonly
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-select
                                    v-model="work_center_group_plant"
                                    label="Related Plant"
                                    :items="plants"
                                    item-text="plant_name"
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-select
                                    v-model="work_center_group_status"
                                    label="Status"
                                    :items="['Active', 'Inactive']"
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-textarea
                                    v-model="work_center_group_description"
                                    counter="50"
                                    label="Description"
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <!--
                                A hidden field that lets us capture the plant ID
                                This is used to auto apply plant name changes to work center groups
                            -->
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    readonly
                                    v-model="work_center_group_plant_id"
                                    :value="getPlantDetails()"
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                    style="visibility: hidden"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        to='/plants-work-centers'
                        color="#ce2458"
                        text
                        x-large
                    >
                    Cancel
                    <v-icon right>mdi-close</v-icon>
                    </v-btn>
                    <!--Delete Button-->
                    <v-btn
                        @click='deleteWorkCenterGroup'
                        v-show="this.user_roles_list.flat().includes('work_orders_delete')"
                        color="#ce2458"
                        text
                        x-large
                        :loading="loading_delete"
                    >
                        Delete
                        <v-icon right>mdi-delete</v-icon>
                    </v-btn>
                    <!--Update Button-->
                    <v-btn
                        @click="updateWorkCenterGroup"
                        color="#33cc99"
                        text
                        x-large
                        :disabled="!valid"
                        :loading="loading_update"
                    >
                        Update
                        <v-icon right>mdi-check</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
    <div v-else>
        <NoPermissionsCard/>
    </div>
</v-container>
</template>

<script>
//Connect to database
import db from '../../../components/firebaseInit'
import firebase from 'firebase'
import FormHeader from '../../../components/Forms/FormHeader'
import NoPermissionsCard from '../../../components/Cards/NoPermissionsCard'
import mixin_UserRoles from '../../../globalActions/mixin_UserRoles'
import store from '../store/store'
import {mapState} from 'vuex'
import {showSnackbar} from '../../../globalActions/index'

export default {
    name: 'NewCategory',
    mixins: [mixin_UserRoles],
    components: {FormHeader, NoPermissionsCard},

    data(){
        return{
            //Initial form values
            valid: null,
            work_center_group_id: null,
            work_center_group_name: null,
            work_center_group_number: null,
            work_center_group_description: null,
            work_center_group_status: null,
            work_center_group_plant: null,
            work_center_group_plant_code: null,
            work_center_group_plant_id: null,
            work_center_group_date_added: null,
            work_center_group_timestamp: null,
            work_center_group_created_by_id: null,
            work_center_group_created_by_name: null,
            plants: [],
            loading: false,
            loading_plants: false,
            loading_update: false,
            loading_delete: false,
            work_center_group_deleted: false,
        }
    },
    //Database query that runs before the page loads
    beforeRouteEnter (to, from, next){
        db.collection('work_center_groups').where('work_center_group_id', '==', to.params.work_center_group_id).get()
        .then(querySnapshot => {
            querySnapshot.forEach(doc =>{
                //vm assigns all the values we want to put in the template
                next(vm => {
                    //Sets template values to those stored in the database
                    vm.work_center_group_id = doc.data().work_center_group_id,
                    vm.work_center_group_name = doc.data().work_center_group_name,
                    vm.work_center_group_number = doc.data().work_center_group_number,
                    vm.work_center_group_description = doc.data().work_center_group_description,
                    vm.work_center_group_status = doc.data().work_center_group_status
                    vm.work_center_group_plant = doc.data().work_center_group_plant                 
                    vm.work_center_group_plant_code = doc.data().work_center_group_plant_code   
                    vm.work_center_group_plant_id = doc.data().work_center_group_plant_id   
                })
            })
        })
    },
    watch: {
        '$route': 'fetchData'
    },
    store,
    computed: mapState({
         //This is where data is retrieved from the Vuex state
        editWorkCenterGroupHeader: state => state.editWorkCenterGroupHeader
    }),
    created(){
        //Captures user that created the sales order
        this.work_center_group_created_by_id = firebase.auth().currentUser.uid
            db.collection('users').where('uid', '==', this.work_center_group_created_by_id).get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
            this.work_center_group_created_by_name = doc.data().displayName
            })
        }),
        //Get plants from DB
        this.loading_plants = true
        db.collection('plants').where('plant_status', '==', 'Active').get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
              const data = doc.data()
              this.plants.push(data)
              this.loading_plants = false
            })
          })
    },
    methods: {
        //Maps database data to template relative to the customer selected in this route
        fetchData () {
        db.collection('work_center_groups').where('work_center_group_id', '==', this.$route.params.work_center_group_id).get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            db.work_center_group_id = doc.data().work_center_group_id
                this.work_center_group_name = doc.data().work_center_group_name
                this.work_center_group_number = doc.data().work_center_group_number
                this.work_center_group_description = doc.data().work_center_group_description
                this.work_center_group_status = doc.data().work_center_group_status
                this.work_center_group_plant = doc.data().work_center_group_plant
                this.work_center_group_plant_code = doc.data().work_center_group_plant_code
                this.work_center_group_plant_id = doc.data().work_center_group_plant_id
          })
        })
      },
        //Update group data on related Work Centers
        updateWorkCenterData(){
            db.collection('work_centers').where('work_center_parent_group_id', '==', this.work_center_group_id)
                .get()
                .then(snapshots => {
                    if (snapshots.size > 0) {
                    snapshots.forEach(data => {
                        if(this.work_center_group_deleted === true){
                            db.collection('work_centers').doc(data.id).update({ 
                                work_center_group: 'Deleted - Reassign',
                                work_center_parent_group_number: 'Deleted - Reassign',
                                work_center_parent_group_id: null,
                                work_center_status: 'Inactive'
                            })
                        }
                        else
                        {
                            db.collection('work_centers').doc(data.id).update({ 
                                work_center_group: this.work_center_group_name,
                                work_center_parent_group_number: this.work_center_group_number,
                            })
                        }
                    })
                }
            })
        },
        //Reroute to work centers table page after database has been updated
        routeToTable(){
            this.loading_update = false
            showSnackbar("Work center group updated successfully");
            this.$router.push('/plants-work-centers')
        },

        updateWorkCenterGroup () {
            //Form validation --> ensures required fields aren't blank before continuing further
            if(this.$refs.form.validate()){
                this.loading_update = true
                db.collection('work_center_groups').where('work_center_group_id', '==', this.$route.params.work_center_group_id).get()
                .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                        doc.ref.update({
                            work_center_group_id: this.work_center_group_id ,
                            work_center_group_name: this.work_center_group_name,
                            work_center_group_number: this.work_center_group_number,
                            work_center_group_description: this.work_center_group_description,
                            work_center_group_status: this.work_center_group_status,
                            work_center_group_plant: this.work_center_group_plant,
                            work_center_group_plant_code: this.work_center_group_plant_code,
                            work_center_group_plant_id: this.work_center_group_plant_id,
                        })
                        .then(() => {
                            //Update group data on related Work Centers
                            this.updateWorkCenterData()
                            //Reroute to work centers table page after database has been updated
                            this.routeToTable()
                        })
                    })
                })
            }
        },
        //Removes group type from database
        deleteWorkCenterGroup () {
            if(confirm('Are you sure you want to delete this work center group?')){
                this.loading_delete = true
                db.collection('work_center_groups').where('work_center_group_id', '==', this.$route.params.work_center_group_id).get()
                .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                        doc.ref.delete()
                        this.work_center_group_deleted = true
                        this.updateWorkCenterData()

                        //Reroute to work centers table page after database has been updated
                        this.routeToTable()
                    })
                })
            }
        },
        //  This function is called on a hidden form field that allows us to retrieve and assign
        //  plant_id & plant_code
        getPlantDetails(){
            db.collection('plants').where('plant_name', '==', this.work_center_group_plant).get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
              const data = doc.data()
               this.work_center_group_plant = data.plant_name
               this.work_center_group_plant_code = data.plant_code
               this.work_center_group_plant_id = data.plant_id
            })
          })
        },
    }
}
</script>