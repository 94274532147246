<template>
    <div>
         <v-container>
            <v-row class="mb-8">
                <v-col lg="12">
                    <v-card-title>
                        <h2>Work Centers</h2>
                        <!--Skeleton button-->
                        <v-skeleton-loader
                          :loading="loading_user"
                          type="button"
                          v-if="firstLoad"
                          class="ml-10"
                        >
                        </v-skeleton-loader>
                        <NewWorkCenterButton
                          v-if="!firstLoad"
                        />
                        <v-spacer/>
                        
                    </v-card-title>
                </v-col>
            </v-row>

            <!--Navigation Tabs-->
            <v-sheet elevation="4">
            <v-tabs
                background-color="#6666cf"
                center-active
                dark
            >
                <v-tab>Work Centers</v-tab>
                <v-tab>Work Center Groups</v-tab>
                <v-tab>Plants</v-tab>
            
            <!--Data tables-->
            <v-tab-item>
                <!--Work Centers-->
                    <!--Table skeleton loader-->
                    <v-row v-if="firstLoad">
                        <v-col>
                        <v-sheet elevation="4">
                            <v-skeleton-loader :loading="loading_user" type="table" max-height="290">
                            </v-skeleton-loader>
                        </v-sheet>
                        </v-col>
                    </v-row>
                    <!--End of skeleton loader-->
                    <v-row v-if="!firstLoad" v-show="this.user_roles_list.flat().includes('work_orders_read')">
                        <v-col>
                            <!--Work centers data table-->
                            <v-card>
                                <v-card-title>
                                    Work Centers<v-spacer></v-spacer>
                                    <v-text-field
                                        v-model="search"
                                        append-icon="mdi-magnify"
                                        label="Search"
                                        single-line
                                        hide-details
                                        clearable
                                    ></v-text-field>
                                </v-card-title>
                                <v-data-table
                                    :headers="workCenterHeaders"
                                    :items="work_centers"
                                    :search="search"
                                    :items-per-page="100"
                                >
                                <!--Edit Work Center icon button-->
                                    <template
                                        v-slot:item.work_center_id={item}
                                        v-if="this.user_roles_list.flat().includes('work_orders_write')"
                                    >
                                        <v-btn
                                            :to="{ name: 'edit-work-center', params: { work_center_id: item.work_center_id }}"
                                            color="#3d2cdd"
                                            icon
                                            small
                                        ><v-icon small>mdi-pencil</v-icon></v-btn>
                                    </template>
                                    <!--View work center-->
                                    <template v-slot:item.work_center_number={item}>
                                        <router-link
                                            :to="{ name: 'view-work-center', params: { work_center_id: item.work_center_id }}">
                                            {{item.work_center_number}}
                                        </router-link>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-tab-item>
                <v-tab-item>
                <!--Work Center Groups-->
                    <!--Table skeleton loader-->
                    <v-row v-if="firstLoad">
                        <v-col>
                        <v-sheet elevation="4">
                            <v-skeleton-loader :loading="loading_user" type="table" max-height="290">
                            </v-skeleton-loader>
                        </v-sheet>
                        </v-col>
                    </v-row>
                    <!--End of skeleton loader-->
                    <v-row v-if="!firstLoad" v-show="this.user_roles_list.flat().includes('work_orders_read')">
                        <v-col>
                            <!--Work centers data table-->
                            <v-card>
                                <v-card-title>
                                    Work Center Groups<v-spacer></v-spacer>
                                    <v-text-field
                                        v-model="search"
                                        append-icon="mdi-magnify"
                                        label="Search"
                                        single-line
                                        hide-details
                                        clearable
                                    ></v-text-field>
                                </v-card-title>
                                <v-data-table
                                    :headers="workCenterGroupHeaders"
                                    :items="filteredWorkCenterGroups"
                                    :search="search"
                                    :items-per-page="100"
                                >
                                <template v-slot:top>
                                    <v-container fluid>
                                        <v-row>
                                    <!--Filter products buy availability status--> 
                                        <v-col lg="3" md="3">
                                        <v-select
                                            :items="['Active','Inactive']"
                                            v-model='workCenterGroupStatus'
                                            label="Status"
                                            outlined
                                            flat
                                            solo
                                            dense
                                            append-icon="mdi-filter-variant"
                                        >
                                            <template>
                                            <v-list-item slot="prepend-item">
                                                <h5>Filter by Status</h5>
                                            </v-list-item>
                                            </template>
                                        </v-select> 
                                        </v-col> 
                                        <v-spacer />
                                        </v-row>
                                    </v-container>
                                    </template>
                                <!--Edit Work Center Group icon button-->
                                    <template
                                        v-slot:item.work_center_group_id={item}
                                        v-if="this.user_roles_list.flat().includes('work_orders_write')"
                                    >
                                        <v-btn
                                            :to="{ name: 'edit-work-center-group', params: { work_center_group_id: item.work_center_group_id }}"
                                            color="#3d2cdd"
                                            icon
                                            small
                                        ><v-icon small>mdi-pencil</v-icon></v-btn>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-tab-item>
                <!--Plants-->
                <v-tab-item>
                    <!--Table skeleton loader-->
                    <v-row v-if="firstLoad">
                        <v-col>
                        <v-sheet elevation="4">
                            <v-skeleton-loader :loading="loading_user" type="table" max-height="290">
                            </v-skeleton-loader>
                        </v-sheet>
                        </v-col>
                    </v-row>
                    <!--End of skeleton loader-->
                    <v-row v-if="!firstLoad" v-show="this.user_roles_list.flat().includes('work_orders_read')">
                    <v-col>
                            <!--Parts data table-->
                            <v-card>
                                <v-card-title>
                                    Plants<v-spacer></v-spacer>
                                    <v-text-field
                                        v-model="search"
                                        append-icon="mdi-magnify"
                                        label="Search"
                                        single-line
                                        hide-details
                                        clearable
                                    ></v-text-field>
                                </v-card-title>
                                <v-data-table
                                    :headers="plantHeaders"
                                    :items="filteredPlants"
                                    :search="search"
                                    :items-per-page="100"
                                >

                                    <template v-slot:top>
                                    <v-container fluid>
                                        <v-row>
                                    <!--Filter products buy availability status--> 
                                        <v-col lg="3" md="3">
                                        <v-select
                                            :items="['Active','Inactive']"
                                            v-model='plantStatus'
                                            label="Status"
                                            outlined
                                            flat
                                            solo
                                            dense
                                            append-icon="mdi-filter-variant"
                                        >
                                            <template>
                                            <v-list-item slot="prepend-item">
                                                <h5>Filter by Status</h5>
                                            </v-list-item>
                                            </template>
                                        </v-select> 
                                        </v-col> 
                                        <v-spacer />
                                        </v-row>
                                    </v-container>
                                    </template>
                                <!--Edit Part icon button-->
                                    <template
                                        v-slot:item.plant_id={item}
                                        v-if="this.user_roles_list.flat().includes('work_orders_write')"
                                    >
                                        <v-btn
                                            :to="{ name: 'edit-plant', params: { plant_id: item.plant_id }}"
                                            color="#3d2cdd"
                                            icon
                                            small
                                        ><v-icon small>mdi-pencil</v-icon></v-btn>
                                    </template>
                                <!--View part -->    
                                    <template v-slot:item.plant_code={item}>
                                        <router-link
                                            :to="{ name: 'view-plant', params: { plant_id: item.plant_id }}">
                                            {{item.plant_code}}
                                        </router-link>
                                    </template>
                                <!--Truncate plant description -->    
                                    <template v-slot:item.plant_description={item}>
                                        <tr>
                                            <td class="truncate">{{ item.plant_description}}</td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-tab-item>
            </v-tabs>
            </v-sheet>
        </v-container>
    </div>
</template>

<script>
import db from '../../../components/firebaseInit' //imports firebase database that was specified in firebaseInit.js
import mixin_UserRoles from '../../../globalActions/mixin_UserRoles'
import NewWorkCenterButton from '../components/NewWorkCenterButton'
import store from '../store/store'
import {mapState} from 'vuex'

export default {
    name: 'WorkOrdersTable',
    mixins: [mixin_UserRoles],
    components: {NewWorkCenterButton},
    data(){
        return {
            search: '',
            plants:[],
            work_center_groups:[],
            work_centers:[],
            plantStatus: 'Active',
            workCenterGroupStatus: 'Active',
        }
    }, 
    store,
    computed: mapState({
        //This is where data is retrieved from the Vuex state
        plantHeaders: state => state.plantHeaders,
        workCenterGroupHeaders: state => state.workCenterGroupHeaders,
        workCenterHeaders: state => state.workCenterHeaders,
        //Filter parts list by on material category
        filteredPlants() {
        return this.plants.filter((i) => 
          {
            return this.plantStatus === '' || (i.plant_status === this.plantStatus)
          })
        },
        filteredWorkCenterGroups() {
        return this.work_center_groups.filter((i) => 
          {
            return this.workCenterGroupStatus === '' || (i.work_center_group_status === this.workCenterGroupStatus)
          })
        },
    }), 
    created(){
        //Get plants from DB
        db.collection('plants').orderBy('plant_name').get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
              const data = doc.data()
              this.plants.push(data)
            })
        }),
        //Get work center groups from DB
        db.collection('work_center_groups').orderBy('work_center_group_number').get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
              const data = doc.data()
              this.work_center_groups.push(data)
            })
        }), 
        //Get work centers from DB
        db.collection('work_centers').orderBy('work_center_name').get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
              const data = doc.data()
              this.work_centers.push(data)
            })
        }) 
    },
}
</script>
<style>
    .truncate {
      max-width: 350px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  </style>