<template>
    <v-container  fluid>
    <v-row dense v-if="this.user_roles_list.flat().includes('work_orders_write')">
        <v-col lg="6" offset-lg="3">
                <FormHeader v-bind='editMachineFormHeader' />
                <v-card>
                <!--New Machine form -->
                <v-form @submit.prevent="updateMachine" class="pa-3" ref="form" v-model="valid">
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    v-model="machine_name"
                                    counter="50"
                                    label="Machine Name"
                                    placeholder="Eg: CNC 1"
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    v-model="machine_number"
                                    label="Machine #"
                                    readonly
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-select
                                    v-model="machine_status"
                                    label="Status"
                                    :items="['Active', 'Inactive']"
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-divider class="py-3"></v-divider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    v-model="machine_make"
                                    label="Make"
                                    placeholder="Eg: Haas..."
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    v-model="machine_model"
                                    label="Model"
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    v-model="machine_serial_number"
                                    label="Serial Number"
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        to='/machines'
                        color="#ce2458"
                        text
                        x-large
                    >
                    Cancel
                    <v-icon right>mdi-close</v-icon>
                    </v-btn>
                    <!--Delete Customer button-->
                    <v-btn
                        @click='deleteMachine'
                        v-show="this.user_roles_list.flat().includes('work_orders_delete')"
                        color="#ce2458"
                        text
                        x-large
                        :loading="loading_delete"
                    >
                        Delete
                        <v-icon right>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn
                        @click="updateMachine"
                        color="#33cc99"
                        text
                        x-large
                        :disabled="!valid"
                        :loading="loading_update"
                    >
                        Update
                        <v-icon right>mdi-check</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
    <div v-else>
        <NoPermissionsCard/>
    </div>
</v-container>
</template>

<script>
//Connect to database
import db from '../../../components/firebaseInit'
import firebase from 'firebase'
import FormHeader from '../../../components/Forms/FormHeader'
import NoPermissionsCard from '../../../components/Cards/NoPermissionsCard'
import mixin_UserRoles from '../../../globalActions/mixin_UserRoles'
import store from '../store/store'
import {mapState} from 'vuex'
import {showSnackbar} from '../../../globalActions/index'

export default {
    name: 'NewMachine',
    mixins: [mixin_UserRoles],
    components: {FormHeader, NoPermissionsCard},

    data(){
        return{
            //Initial form values
            valid: null,
            machine_id: null,
            machine_name: null,
            machine_number: null,
            machine_status: 'Active',
            machine_make: null,
            machine_model: null,
            machine_serial_number: null,
            machine_work_center_name: null,
            machine_work_center_id: null,
            machine_date_added: null,
            machine_timestamp: null,
            machine_created_by_id: null,
            machine_created_by_name: null,
            loading: false,
            loading_update: false,
            loading_delete: false,
        }
    },
    //Database query that runs before the page loads
    beforeRouteEnter (to, from, next){
        db.collection('machines').where('machine_id', '==', to.params.machine_id).get()
        .then(querySnapshot => {
            querySnapshot.forEach(doc =>{
                //vm assigns all the values we want to put in the template
                next(vm => {
                    //Sets template values to those stored in the database
                    vm.machine_id = doc.data().machine_id,
                    vm.machine_name = doc.data().machine_name,
                    vm.machine_number = doc.data().machine_number,
                    vm.machine_status = doc.data().machine_status,
                    vm.machine_make = doc.data().machine_make,  
                    vm.machine_model = doc.data().machine_model, 
                    vm.machine_serial_number = doc.data().machine_serial_number,                
                    vm.machine_work_center_name = doc.data().machine_work_center_name, 
                    vm.machine_work_center_id = doc.data().machine_work_center_id
                })
            })
        })
    },
    watch: {
        '$route': 'fetchData'
    },
    store,
    computed: mapState({
         //This is where data is retrieved from the Vuex state
        editMachineFormHeader: state => state.editMachineFormHeader
    }),
    created(){
        //Captures user that created the sales order
        this.machine_created_by_id = firebase.auth().currentUser.uid
            db.collection('users').where('uid', '==', this.machine_created_by_id).get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
            this.machine_created_by_name = doc.data().displayName
            })
        })
    },
    methods: {
        //Maps database data to template relative to the customer selected in this route
        fetchData () {
        db.collection('machines').where('machine_id', '==', this.$route.params.machine_id).get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            db.machine_id = doc.data().machine_id
                this.machine_name = doc.data().machine_name
                this.machine_number = doc.data().machine_number
                this.machine_status = doc.data().machine_status
                this.machine_make = doc.data().machine_make
                this.machine_model = doc.data().machine_model
                this.machine_serial_number = doc.data().machine_serial_number
                this.machine_work_center_name = doc.data().machine_work_center_name
                this.machine_work_center_id = doc.data().machine_work_center_id
          })
        })
      },
        //Reroute to machine table page after database has been updated
        routeToTable(){
            this.loading_update = false
            showSnackbar("Machine added successfully");
            this.$router.push('/machines')
        },
        updateMachine () {
            //Form validation --> ensures required fields aren't blank before continuing further
            if(this.$refs.form.validate()){
                this.loading_update = true
                db.collection('machines').where('machine_id', '==', this.$route.params.machine_id).get()
                .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                        doc.ref.update({
                            machine_id: this.machine_id ,
                            machine_name: this.machine_name,
                            machine_number: this.machine_number,
                            machine_status: this.machine_status,
                            machine_make: this.machine_make,
                            machine_model: this.machine_model,
                            machine_serial_number: this.machine_serial_number,
                            machine_work_center_name: this.machine_work_center_name,
                            machine_work_center_id: this.machine_work_center_id,
                        })
                        .then(() => {
                            //Route back to datatable
                            this.routeToTable()
                        })
                    })
                })
            }
            
        },
        deleteMachine(){
            if(confirm('Are you sure you want to delete this machine?')){
                this.loading_delete = true
                db.collection('machines').where('machine_id', '==', this.$route.params.machine_id).get()
                .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                        doc.ref.delete()
                        //Reroute to machine table page after database has been updated
                        this.routeToTable()
                    })
                })
            }
        }
    }
}
</script>