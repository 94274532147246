<template>
    <v-container  fluid>
    <v-row dense v-if="this.user_roles_list.flat().includes('work_orders_write')">
        <v-col lg="6" offset-lg="3">
                <FormHeader v-bind='newWorkCenterGroupHeader' />
                <v-card>
                <!--New Work Center Group form -->
                <v-form @submit.prevent="saveWorkCenterGroup" class="pa-3" ref="form" v-model="valid">
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    v-model="work_center_group_name"
                                    counter="50"
                                    label="Group Name"
                                    placeholder="Eg: Assembly Line #1..."
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    v-model="work_center_group_number"
                                    label="Group #"
                                    readonly
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-select
                                    v-model="work_center_group_plant"
                                    label="Related Plant"
                                    :items="plants"
                                    item-text="plant_name"
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-select
                                    v-model="work_center_group_status"
                                    label="Status"
                                    :items="['Active', 'Inactive']"
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-textarea
                                    v-model="work_center_group_description"
                                    counter="50"
                                    label="Description"
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <!--
                                A hidden field that lets us capture the plant ID
                                This is used to auto apply plant name changes to work center groups
                            -->
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    readonly
                                    v-model="work_center_group_plant_id"
                                    :value="getPlantDetails()"
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                    style="visibility: hidden"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        to='/plants-work-centers'
                        color="#ce2458"
                        text
                        x-large
                    >
                    Cancel
                    <v-icon right>mdi-close</v-icon>
                    </v-btn>
                    <v-btn
                        @click="saveWorkCenterGroup"
                        color="#33cc99"
                        text
                        x-large
                        :disabled="!valid"
                        :loading="loading"
                    >
                        Submit
                        <v-icon right>mdi-check</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
    <div v-else>
        <NoPermissionsCard/>
    </div>
</v-container>
</template>

<script>
//Connect to database
import db from '../../../components/firebaseInit'
import firebase from 'firebase'
import FormHeader from '../../../components/Forms/FormHeader'
import NoPermissionsCard from '../../../components/Cards/NoPermissionsCard'
import mixin_UserRoles from '../../../globalActions/mixin_UserRoles'
import store from '../store/store'
import {mapState} from 'vuex'
import {showSnackbar} from '../../../globalActions/index'

export default {
    name: 'NewCategory',
    mixins: [mixin_UserRoles],
    components: {FormHeader, NoPermissionsCard},

    data(){
        return{
            //Initial form values
            valid: null,
            work_center_group_id: null,
            work_center_group_name: null,
            work_center_group_number: null,
            work_center_group_description: null,
            work_center_group_status: 'Active',
            work_center_group_plant: null,
            work_center_group_plant_code: null,
            work_center_group_plant_id: null,
            work_center_group_date_added: null,
            work_center_group_timestamp: null,
            work_center_group_created_by_id: null,
            work_center_group_created_by_name: null,
            plants: [],
            loading: false,
            loading_plants: false,
        }
    },
    store,
    computed: mapState({
         //This is where data is retrieved from the Vuex state
        newWorkCenterGroupHeader: state => state.newWorkCenterGroupHeader
    }),
    created(){
        //Captures user that created the sales order
        this.work_center_group_created_by_id = firebase.auth().currentUser.uid
            db.collection('users').where('uid', '==', this.work_center_group_created_by_id).get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
            this.work_center_group_created_by_name = doc.data().displayName
            })
        }),
        //Get plants from DB
        this.loading_plants = true
        db.collection('plants').where('plant_status', '==', 'Active').get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
              const data = doc.data()
              this.plants.push(data)
              this.loading_plants = false
            })
          })
        //Get Work Center Group counter from database
        db.collection('work_center_group_counter')
        .doc('BwF4ilAieZlE3Yrx6wdx').get().then(snapshot => {
            // Gets corder and quote counter object from datatbase
            const count = snapshot.data().work_center_group_count
            if(count < 10){
                this.work_center_group_number = `WCG-000${count}`  
            }else if (count >= 10 && count < 100){
                this.work_center_group_number = `WCG-00${count}`
            }else if (count >= 100 && count < 1000){
                this.work_center_group_number = `WCG-0${count}`
            }else {
                this.work_center_group_number = `WCG-${count}`
            }        
        })
    },
    methods: {
        //Reroute to plant list after database is updated
        routeToTable(){
            this.loading = false
            showSnackbar("New Work Center Group added successfully");
            this.$router.push('/plants-work-centers')
        },
        //Increment Work Center Group number counter
        increaseWorkCenterGroupCount(){
            const db = firebase.firestore()
            const increment = firebase.firestore.FieldValue.increment(1)
            const workCenterGroupNumberCount = db.collection('work_center_group_counter').doc('BwF4ilAieZlE3Yrx6wdx')
            workCenterGroupNumberCount.update({ work_center_group_count: increment })
        },
        saveWorkCenterGroup () {
            //Form validation --> ensures required fields aren't blank before continuing further           
            if(this.$refs.form.validate()){
                this.loading = true
                //Sets the category_id == to the document id in database
                var newDocRef = db.collection('work_center_groups').doc();
                newDocRef.set({
                    //Populated by the v-model values in the form
                    work_center_group_id: newDocRef.id,
                    work_center_group_name: this.work_center_group_name,
                    work_center_group_number: this.work_center_group_number,
                    work_center_group_status: this.work_center_group_status,
                    work_center_group_description: this.work_center_group_description,
                    work_center_group_plant: this.work_center_group_plant,
                    work_center_group_plant_code: this.work_center_group_plant_code,
                    work_center_group_plant_id: this.work_center_group_plant_id,
                    work_center_group_date_added: new Date().toISOString().slice(0, 10),
                    work_center_group_timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                    work_center_group_created_by_id: this.work_center_group_created_by_id,
                    work_center_group_created_by_name: this.work_center_group_created_by_name,
                })
                .then(() => {
                    //Increment Work Center Group number counter
                    this.increaseWorkCenterGroupCount()
                    //Reroute to plant list after database is updated
                    this.routeToTable()
                })
            }
        },
        //  This function is called on a hidden form field that allows us to retrieve and assign
        //  plant_id & plant_code
        getPlantDetails(){
            db.collection('plants').where('plant_name', '==', this.work_center_group_plant).get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
              const data = doc.data()
               this.work_center_group_plant = data.plant_name
               this.work_center_group_plant_code = data.plant_code
               this.work_center_group_plant_id = data.plant_id
            })
          })
        },
    }
}
</script>