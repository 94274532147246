<template>
    <div>
        <MachinesTable />
    </div>
</template>

<script>
import MachinesTable from '../components/MachinesTable'
export default {
    name: 'Machines',
    components: {MachinesTable}
}
</script>