<template>
    <v-container  fluid>
    <v-row dense v-if="this.user_roles_list.flat().includes('work_orders_write')">
        <v-col lg="6" offset-lg="3">
                <FormHeader v-bind='newPlantHeader' />
                <v-card>
                <!--New Material Type form -->
                <v-form @submit.prevent="savePlant" class="pa-3" ref="form" v-model="valid">
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    v-model="plant_name"
                                    counter="50"
                                    label="Plant Name"
                                    placeholder="Eg: Main Warehouse..."
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    v-model="plant_code"
                                    counter="50"
                                    label="Plant Code"
                                    readonly
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-select
                                    v-model="plant_status"
                                    label="Status"
                                    :items="['Active', 'Inactive']"
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-textarea
                                    v-model="plant_description"
                                    counter="500"
                                    label="Description"
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        to='/materials'
                        color="#ce2458"
                        text
                        x-large
                    >
                    Cancel
                    <v-icon right>mdi-close</v-icon>
                    </v-btn>
                    <v-btn
                        @click="savePlant"
                        color="#33cc99"
                        text
                        x-large
                        :disabled="!valid"
                        :loading="loading"
                    >
                        Submit
                        <v-icon right>mdi-check</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
    <div v-else>
        <NoPermissionsCard/>
    </div>
</v-container>
</template>

<script>
//Connect to database
import db from '../../../components/firebaseInit'
import firebase from 'firebase'
import FormHeader from '../../../components/Forms/FormHeader'
import NoPermissionsCard from '../../../components/Cards/NoPermissionsCard'
import mixin_UserRoles from '../../../globalActions/mixin_UserRoles'
import store from '../store/store'
import {mapState} from 'vuex'
import {showSnackbar} from '../../../globalActions/index'

export default {
    name: 'NewCategory',
    mixins: [mixin_UserRoles],
    components: {FormHeader, NoPermissionsCard},

    data(){
        return{
            //Initial form values
            valid: null,
            plant_id: null,
            plant_name: null,
            plant_code: null,
            plant_status: 'Active',
            plant_description: null,
            plant_date_added: null,
            plant_timestamp: null,
            plant_created_by_id: null,
            plant_created_by_name: null,
            loading: false,
        }
    },
    store,
    computed: mapState({
         //This is where data is retrieved from the Vuex state
        newPlantHeader: state => state.newPlantHeader
    }),
    created(){
        //Captures user that created the sales order
        this.plant_created_by_id = firebase.auth().currentUser.uid
            db.collection('users').where('uid', '==', this.plant_created_by_id).get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
            this.plant_created_by_name = doc.data().displayName
            })
        }),
        //Get Plant counter from database
        db.collection('plant_counter')
        .doc('PNpzvIjpjfAu5kUuqEPS').get().then(snapshot => {
            // Gets corder and quote counter object from datatbase
            const count = snapshot.data().plant_count   
            if(count < 10){
                this.plant_code = `PLT-000${count}`  
            }else if (count >= 10 && count < 100){
                this.plant_code = `PLT-00${count}`
            }else if (count >= 100 && count < 1000){
                this.plant_code = `PLT-0${count}`
            }else {
                this.plant_code = `PLT-${count}`
            }   
        })
    },
    methods: {
        routeToTable(){
            //Reroute to plant list after database is updated
            this.loading = false
            showSnackbar("New plant added successfully");
            this.$router.push('/plants-work-centers')
        },
        increasePlantCounter(){
            //Increments plant counter
            const db = firebase.firestore()
            const increment = firebase.firestore.FieldValue.increment(1)
            const plantNumberCount = db.collection('plant_counter').doc('PNpzvIjpjfAu5kUuqEPS')
            plantNumberCount.update({ plant_count: increment });
        },
        savePlant () {
            //Form validation --> ensures required fields aren't blank before continuing further           
            if(this.$refs.form.validate()){
                this.loading = true
                //Sets the category_id == to the document id in database
                var newDocRef = db.collection('plants').doc();
                newDocRef.set({
                    //Populated by the v-model values in the form
                    plant_id: newDocRef.id,
                    plant_name: this.plant_name,
                    plant_code: this.plant_code,
                    plant_status: this.plant_status,
                    plant_description: this.plant_description,
                    plant_date_added: new Date().toISOString().slice(0, 10),
                    plant_timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                    plant_created_by_id: this.plant_created_by_id,
                    plant_created_by_name: this.plant_created_by_name,
                })
                .then(() => {
                        //Increments plant counter
                        this.increasePlantCounter()
                        //Reroute to plant list after database is updated
                        this.routeToTable()
                })
            }
        },
    }
}
</script>