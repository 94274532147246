<template>
    <v-container  fluid>
    <v-row dense v-if="this.user_roles_list.flat().includes('work_orders_write')">
        <v-col lg="6" offset-lg="3">
                <FormHeader v-bind='editPlantHeader' />
                <v-card>
                <!--New Material Type form -->
                <v-form @submit.prevent="updatePlant" class="pa-3" ref="form" v-model="valid">
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    v-model="plant_name"
                                    counter="50"
                                    label="Plant Name"
                                    placeholder="Eg: Main Warehouse..."
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    v-model="plant_code"
                                    counter="50"
                                    label="Plant Code"
                                    readonly
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-select
                                    v-model="plant_status"
                                    label="Status"
                                    :items="['Active', 'Inactive']"
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-textarea
                                    v-model="plant_description"
                                    label="Description"
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        to='/plants-work-centers'
                        color="#ce2458"
                        text
                        x-large
                    >
                    Cancel
                    <v-icon right>mdi-close</v-icon>
                    </v-btn>
                    <!--Delete Customer button-->
                    <v-btn
                        @click='deletePlant'
                        v-show="this.user_roles_list.flat().includes('work_orders_delete')"
                        color="#ce2458"
                        text
                        x-large
                        :loading="loading_delete"
                    >
                        Delete
                        <v-icon right>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn
                        @click="updatePlant"
                        color="#33cc99"
                        text
                        x-large
                        :disabled="!valid"
                        :loading="loading_update"
                    >
                        Update
                        <v-icon right>mdi-check</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
    <div v-else>
        <NoPermissionsCard/>
    </div>
</v-container>
</template>

<script>
//Connect to database
import db from '../../../components/firebaseInit'
import firebase from 'firebase'
import FormHeader from '../../../components/Forms/FormHeader'
import NoPermissionsCard from '../../../components/Cards/NoPermissionsCard'
import mixin_UserRoles from '../../../globalActions/mixin_UserRoles'
import store from '../store/store'
import {mapState} from 'vuex'
import {showSnackbar} from '../../../globalActions/index'

export default {
    name: 'NewCategory',
    mixins: [mixin_UserRoles],
    components: {FormHeader, NoPermissionsCard},

    data(){
        return{
            //Initial form values
            valid: null,
            plant_id: null,
            plant_name: null,
            plant_code: null,
            plant_status: null,
            plant_description: null,
            plant_date_added: null,
            plant_timestamp: null,
            plant_created_by_id: null,
            plant_created_by_name: null,
            loading: false,
            loading_update: false,
            loading_delete: false,
            work_plant_deleted: false,
        }
    },
    //Database query that runs before the page loads
    beforeRouteEnter (to, from, next){
        db.collection('plants').where('plant_id', '==', to.params.plant_id).get()
        .then(querySnapshot => {
            querySnapshot.forEach(doc =>{
                //vm assigns all the values we want to put in the template
                next(vm => {
                    //Sets template values to those stored in the database
                    vm.plant_id = doc.data().plant_id,
                    vm.plant_name = doc.data().plant_name,
                    vm.plant_code = doc.data().plant_code,
                    vm.plant_status = doc.data().plant_status,
                    vm.plant_description = doc.data().plant_description            
                })
            })
        })
    },
    watch: {
        '$route': 'fetchData'
    },
    store,
    computed: mapState({
         //This is where data is retrieved from the Vuex state
        editPlantHeader: state => state.editPlantHeader
    }),
    created(){
        //Captures user that created the sales order
        this.plant_created_by_id = firebase.auth().currentUser.uid
            db.collection('users').where('uid', '==', this.plant_created_by_id).get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
            this.plant_created_by_name = doc.data().displayName
            })
        }) 
    },
    methods: {
        //Maps database data to template relative to the customer selected in this route
        fetchData () {
        db.collection('plants').where('plant_id', '==', this.$route.params.plant_id).get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            db.plant_id = doc.data().plant_id
                this.plant_name = doc.data().plant_name
                this.plant_code = doc.data().plant_code
                this.plant_status = doc.data().plant_status
                this.plant_description = doc.data().plant_description
          })
        })
      },
        //Update plant on work center groups
        updateWorkCenterGroupData(){
            db.collection('work_center_groups').where('work_center_group_plant_id', '==', this.plant_id)
            .get()
            .then(snapshots => {
                if (snapshots.size > 0) {
                    snapshots.forEach(data => {
                        if(this.work_plant_deleted === true){
                            db.collection('work_center_groups').doc(data.id).update({ 
                                work_center_group_plant: 'Deleted - Reassign',
                                work_center_group_plant_code: 'Deleted - Reassign',
                                work_center_group_plant_id: null,
                            })
                        }
                        else{
                            db.collection('work_center_groups').doc(data.id).update({ 
                                work_center_group_plant: this.plant_name,
                                work_center_group_plant_code: this.plant_code
                            })
                        }
                    })
                }
            })
        },
        //Update plant on work centers
        updateWorkCenterData(){
            db.collection('work_centers').where('work_center_plant_id', '==', this.plant_id)
                .get()
                .then(snapshots => {
                    if (snapshots.size > 0) {
                    snapshots.forEach(data => {
                        if(this.work_plant_deleted === true){
                            db.collection('work_centers').doc(data.id).update({ 
                                work_center_plant_name: 'Deleted - Reassign',
                                work_center_plant_code: 'Deleted - Reassign',
                                work_center_status: 'Inactive',
                                work_center_plant_id: null,
                            })
                        }
                        else{
                            db.collection('work_centers').doc(data.id).update({ 
                                work_center_plant_name: this.plant_name,
                                work_center_plant_code: this.plant_code,
                            })
                        }
                        
                    })
                }
            })
        },
        //Reroute to work centers table page after database has been updated
        routeToTable(){
            this.loading_update = false
            showSnackbar("Work center group updated successfully");
            this.$router.push('/plants-work-centers')
        },

        //Updates form values in the database
        updatePlant(){
            //Form validation --> ensures required fields aren't blank before continuing further
            if(this.$refs.form.validate()){
                this.loading_update = true
                db.collection('plants').where('plant_id', '==', this.$route.params.plant_id).get()
                .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                        doc.ref.update({
                            plant_id: this.plant_id ,
                            plant_name: this.plant_name,
                            plant_code: this.plant_code,
                            plant_status: this.plant_status,
                            plant_description: this.plant_description,
                        })
                        .then(() => {
                            //Update plant on work center groups
                            this.updateWorkCenterGroupData()
                            //Update plant on work centers
                            this.updateWorkCenterData()
                            //Redirects back to work center table
                            this.routeToTable()
                        })
                    })
                })
            }  
        },
        //Removes material type from database
        deletePlant () {
            if(confirm('Are you sure you want to delete this plant?')){
                this.loading_delete = true
                db.collection('plants').where('plant_id', '==', this.$route.params.plant_id).get()
                .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                        doc.ref.delete()
                        this.work_plant_deleted = true
                        //Update plant on work center groups
                        this.updateWorkCenterGroupData()
                        //Update plant on work centers
                        this.updateWorkCenterData()
                        //Redirects back to work center table
                        this.routeToTable()
                    })
                })
            }
        },
    }
}
</script>