<template>
    <div>
        <WorkCentersTable />
    </div>
</template>

<script>
import WorkCentersTable from '../components/WorkCentersTable'
export default {
    name: 'WorkCenters',
    components: {WorkCentersTable}
}
</script>