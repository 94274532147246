<template>
    <v-container  fluid>
    <v-row dense v-if="this.user_roles_list.flat().includes('work_orders_write')">
        <v-col lg="6" offset-lg="3">
                <FormHeader v-bind='newMachineFormHeader' />
                <v-card>
                <!--New Machine form -->
                <v-form @submit.prevent="saveMachine" class="pa-3" ref="form" v-model="valid">
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    v-model="machine_name"
                                    counter="50"
                                    label="Machine Name"
                                    placeholder="Eg: CNC 1"
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    v-model="machine_number"
                                    label="Machine #"
                                    readonly
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-select
                                    v-model="machine_status"
                                    label="Status"
                                    :items="['Active', 'Inactive']"
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-divider class="py-3"></v-divider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    v-model="machine_make"
                                    label="Make"
                                    placeholder="Eg: Haas..."
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    v-model="machine_model"
                                    label="Model"
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    v-model="machine_serial_number"
                                    label="Serial Number"
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        to='/machines'
                        color="#ce2458"
                        text
                        x-large
                    >
                    Cancel
                    <v-icon right>mdi-close</v-icon>
                    </v-btn>
                    <v-btn
                        @click="saveMachine"
                        color="#33cc99"
                        text
                        x-large
                        :disabled="!valid"
                        :loading="loading"
                    >
                        Submit
                        <v-icon right>mdi-check</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
    <div v-else>
        <NoPermissionsCard/>
    </div>
</v-container>
</template>

<script>
//Connect to database
import db from '../../../components/firebaseInit'
import firebase from 'firebase'
import FormHeader from '../../../components/Forms/FormHeader'
import NoPermissionsCard from '../../../components/Cards/NoPermissionsCard'
import mixin_UserRoles from '../../../globalActions/mixin_UserRoles'
import store from '../store/store'
import {mapState} from 'vuex'
import {showSnackbar} from '../../../globalActions/index'

export default {
    name: 'NewMachine',
    mixins: [mixin_UserRoles],
    components: {FormHeader, NoPermissionsCard},

    data(){
        return{
            //Initial form values
            valid: null,
            machine_id: null,
            machine_name: null,
            machine_number: null,
            machine_status: 'Active',
            machine_make: null,
            machine_model: null,
            machine_serial_number: null,
            machine_work_center_name: null,
            machine_work_center_id: null,
            machine_date_added: null,
            machine_timestamp: null,
            machine_created_by_id: null,
            machine_created_by_name: null,
            loading: false,
        }
    },
    store,
    computed: mapState({
         //This is where data is retrieved from the Vuex state
        newMachineFormHeader: state => state.newMachineFormHeader
    }),
    created(){
        //Captures user that created the sales order
        this.machine_created_by_id = firebase.auth().currentUser.uid
            db.collection('users').where('uid', '==', this.machine_created_by_id).get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
            this.machine_created_by_name = doc.data().displayName
            })
        }),
        //Get machine counter from database
        db.collection('machine_counter')
        .doc('E65pE8d08I35ZuLhUylF').get().then(snapshot => {
            const count = snapshot.data().machine_count
            this.machine_number = `MCH-000${count}`  
            if(count < 10){
                this.machine_number = `MCH-000${count}`  
            }else if (count >= 10 && count < 100){
                this.machine_number = `MCH-00${count}`
            }else if (count >= 100 && count < 1000){
                this.machine_number = `MCH-0${count}`
            }else {
                this.machine_number = `MCH-${count}`
            }       
        })
    },
    methods: {
        //Increment machine count
        incrementMachineCount(){
            const db = firebase.firestore();
            const increment = firebase.firestore.FieldValue.increment(1);
            const machineNumberCount = db.collection('machine_counter').doc('E65pE8d08I35ZuLhUylF');
            //Increments work order counter
            machineNumberCount.update({ machine_count: increment });
        },
        //Reroute to machine table page after database has been updated
        routeToTable(){
            this.loading_update = false
            showSnackbar("Machine added successfully");
            this.$router.push('/machines')
        },

        saveMachine () {
            //Form validation --> ensures required fields aren't blank before continuing further           
            if(this.$refs.form.validate()){
                this.loading = true
                //Sets the category_id == to the document id in database
                var newDocRef = db.collection('machines').doc();
                newDocRef.set({
                    //Populated by the v-model values in the form
                    machine_id: newDocRef.id,
                    machine_name: this.machine_name,
                    machine_number: this.machine_number,
                    machine_make: this.machine_make,
                    machine_model: this.machine_model,
                    machine_serial_number: this.machine_serial_number,
                    machine_status: this.machine_status,
                    machine_work_center_name: this.machine_work_center_name,
                    machine_work_center_id: this.machine_work_center_id,
                    machine_date_added: new Date().toISOString().slice(0, 10),
                    machine_timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                    machine_created_by_id: this.machine_created_by_id,
                    machine_created_by_name: this.machine_created_by_name,
                })
                //Reroute to customers list after database is updated
                .then(() => {
                    //Increment machine count
                    this.incrementMachineCount()
                    //Reroute to machine table page after database has been updated
                    this.routeToTable()
                })
            }
        }
    }
}
</script>