<template>
    <div>
         <v-container>
            <v-row class="mb-8">
                <v-col lg="12">
                    <v-card-title>
                        <h2>Machines</h2>
                        <!--Skeleton button-->
                        <v-skeleton-loader
                          :loading="loading_user"
                          type="button"
                          v-if="firstLoad"
                          class="ml-10"
                        >
                        </v-skeleton-loader>
                        <!--Add category button-->
                        <NewMachineButton
                          v-if="!firstLoad"
                          v-show="this.user_roles_list.flat().includes('work_orders_write')"
                        />
                    </v-card-title>
                </v-col>
            </v-row>
            <!--Table skeleton loader-->
              <v-row v-if="firstLoad">
                <v-col>
                  <v-sheet elevation="4">
                    <v-skeleton-loader :loading="loading_user" type="table" max-height="500">
                    </v-skeleton-loader>
                  </v-sheet>
                </v-col>
              </v-row>
              <!--End of skeleton loader-->
            <v-row v-if="!firstLoad" v-show="this.user_roles_list.flat().includes('work_orders_read')">
                <v-col>
                    <v-sheet elevation="4">
                        <!--Machine data table-->    
                        <v-data-table
                            :headers="machineHeaders"
                            :items="filteredMachines"
                            :search="search"
                            :items-per-page="100"
                        >
                            <template v-slot:top>
                                <v-container fluid>
                                    <v-row>
                                <!--Filter products buy availability status--> 
                                    <v-col lg="3" md="3">
                                    <v-select
                                        :items="['Active','Inactive']"
                                        v-model='machineStatus'
                                        label="Status"
                                        outlined
                                        flat
                                        solo
                                        dense
                                        append-icon="mdi-filter-variant"
                                    >
                                        <template>
                                        <v-list-item slot="prepend-item">
                                            <h5>Filter by Status</h5>
                                        </v-list-item>
                                        </template>
                                    </v-select> 
                                    </v-col> 
                                    <v-spacer />
                                    <!--Search Bar-->
                                        <v-col lg="6" md="6">
                                            <v-text-field
                                                v-model="search"
                                                append-icon="mdi-magnify"
                                                label="Search"
                                                single-line
                                                hide-details
                                                clearable
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </template>
                            <!--Edit machine icon button-->
                            <template
                                v-slot:item.machine_id={item}
                                v-if="this.user_roles_list.flat().includes('work_orders_write')"
                            >
                                <v-btn
                                    :to="{ name: 'edit-machine', params: { machine_id: item.machine_id }}"
                                    color="#3d2cdd"
                                    icon
                                    small
                                ><v-icon small>mdi-pencil</v-icon></v-btn>
                            </template>
                        </v-data-table>
                    </v-sheet>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import db from '../../../components/firebaseInit' //imports firebase database that was specified in firebaseInit.js
import mixin_UserRoles from '../../../globalActions/mixin_UserRoles'
import NewMachineButton from './NewMachineButton'
import store from '../store/store'
import {mapState} from 'vuex'
export default {
    name: 'CategoriesTable',
    mixins: [mixin_UserRoles],
    components: {NewMachineButton},
    data () {
      return {
        pagination: {},
        search: '',
        machines: [],
        machineStatus: 'Active',

      }
    },
    // Gets machines from firebase datatbase
    created(){
      db.collection('machines').get().then(querySnapshot => {
        this.categories = []
        querySnapshot.forEach(doc => {
          const data = {
            'id': doc.id,
            'machine_id': doc.data().machine_id,
            'machine_name': doc.data().machine_name,
            'machine_number': doc.data().machine_number,
            'machine_work_center': doc.data().machine_work_center,  
            'machine_date_added': doc.data().machine_date_added,
            'machine_status': doc.data().machine_status,
          }
          //Pushes the categories objects to the empty categories array above
          this.machines.push(data)
        })
      })
    },
    store,
    computed: mapState({
        //This is where data is retrieved from the Vuex state
        machineHeaders: state => state.machineHeaders,
        //Filter machines list by status
        filteredMachines() {
        return this.machines.filter((i) => 
          {
            return this.machineStatus === '' || (i.machine_status === this.machineStatus)
          })
        },
    }), 
}
</script>