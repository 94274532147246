<template>
    <v-container  fluid>
    <v-row dense v-if="this.user_roles_list.flat().includes('work_orders_write')">
        <v-col lg="8" offset-lg="2">
                <FormHeader v-bind='newWorkCenterHeader' />
                <v-card>
                    <!--New Work Center form -->
                    <v-form @submit.prevent="saveWorkCenter" ref="form" v-model="valid">
                        <!--Form field tabs-->
                        <v-tabs
                            background-color="#6666cf"
                            center-active
                            dark
                        >
                        <v-tab>Basic Info</v-tab>
                        <v-tab>Machines</v-tab>
                        <v-tab>Employees</v-tab>
                        
                        <!--Basic Info tab-->
                        <v-tab-item>
                        <v-container class="pa-3">
                            <v-row>
                                <v-col cols="12" sm="12" md="4">
                                    <v-text-field
                                        v-model="work_center_name"
                                        counter="50"
                                        label="Work Center Name"
                                        placeholder="Eg: CNC Machine..."
                                        required
                                        outlined
                                        dense
                                        color="#3d2cdd"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <v-text-field
                                        v-model="work_center_number"
                                        label="Work Center #"
                                        readonly
                                        required
                                        outlined
                                        dense
                                        color="#3d2cdd"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="12" md="4">
                                    <v-select
                                        v-model="work_center_group"
                                        label="Related Group"
                                        :items="work_center_groups"
                                        item-text="work_center_group_name"
                                        required
                                        outlined
                                        dense
                                        color="#3d2cdd"
                                    >
                                    </v-select>
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <v-select
                                        v-model="work_center_status"
                                        label="Status"
                                        :items="['Active', 'Inactive']"
                                        required
                                        outlined
                                        dense
                                        color="#3d2cdd"
                                    >
                                    </v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="12" md="12">
                                    <v-textarea
                                        v-model="work_center_description"
                                        counter="50"
                                        label="Description"
                                        required
                                        outlined
                                        dense
                                        color="#3d2cdd"
                                    >
                                    </v-textarea>
                                </v-col>
                            </v-row>
                            <v-row>
                                <!--
                                    A hidden field that lets us capture the Work Center Group ID
                                    This is used to auto apply plant name changes to work center groups
                                -->
                                <v-col cols="12" sm="12" md="6">
                                    <v-text-field
                                        readonly
                                        v-model="work_center_parent_group_id"
                                        :value="getWorkCenterGroupDetails()"
                                        required
                                        outlined
                                        dense
                                        color="#3d2cdd"
                                        style="visibility: hidden"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                    <!--End of Basic Details tab-->
                    <!--Machines Tab-->
                    <v-tab-item>
                        <v-container class="pa-3">
                            <v-row>
                                <v-col cols="12" sm="12" md="12">
                                    <v-data-table
                                        v-model="work_center_machines"
                                        :headers="machineSelectionHeaders"
                                        :items="machines"
                                        item-key="machine_id"
                                        :search="search"
                                        :items-per-page="10000"
                                        hide-default-footer
                                        show-select  
                                        sort-by="machine_number"                            
                                    >
                                    <!--Search Filter-->
                                    <template v-slot:top>
                                        <v-row>
                                            <v-spacer></v-spacer>
                                            <v-col cols="12" sm="12" md="6">
                                                <v-text-field
                                                    v-model="search"
                                                    append-icon="mdi-magnify"
                                                    label="Search"
                                                    single-line
                                                    hide-details
                                                    clearable
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                    <!--End of machines tab-->
                    <!--Employees Tab-->
                    <v-tab-item>
                        <v-container class="pa-3">
                            <v-row>
                                <v-col cols="12" sm="12" md="12">
                                    <v-data-table
                                        v-model="work_center_employees"
                                        :headers="employeeSelectionHeaders"
                                        :items="employees"
                                        item-key="employee_id"
                                        :search="search"
                                        :items-per-page="10000"
                                        hide-default-footer
                                        show-select  
                                        sort-by="employee_number"                            
                                    >
                                    <!--Search Filter-->
                                    <template v-slot:top>
                                        <v-row>
                                            <v-spacer></v-spacer>
                                            <v-col cols="12" sm="12" md="6">
                                                <v-text-field
                                                    v-model="search"
                                                    append-icon="mdi-magnify"
                                                    label="Search"
                                                    single-line
                                                    hide-details
                                                    clearable
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                    <!--End of employees tab-->
                    </v-tabs>

                </v-form>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    to='/plants-work-centers'
                    color="#ce2458"
                    text
                    x-large
                >
                Cancel
                <v-icon right>mdi-close</v-icon>
                </v-btn>
                <v-btn
                    @click="saveWorkCenter"
                    color="#33cc99"
                    text
                    x-large
                    :disabled="!valid"
                    :loading="loading"
                >
                    Submit
                    <v-icon right>mdi-check</v-icon>
                </v-btn>
            </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
    <div v-else>
        <NoPermissionsCard/>
    </div>
</v-container>
</template>

<script>
//Connect to database
import db from '../../../components/firebaseInit'
import firebase from 'firebase'
import FormHeader from '../../../components/Forms/FormHeader'
import NoPermissionsCard from '../../../components/Cards/NoPermissionsCard'
import mixin_UserRoles from '../../../globalActions/mixin_UserRoles'
import store from '../store/store'
import {mapState} from 'vuex'
import {showSnackbar} from '../../../globalActions/index'

export default {
    name: 'NewCategory',
    mixins: [mixin_UserRoles],
    components: {FormHeader, NoPermissionsCard},

    data(){
        return{
            //Initial form values
            valid: null,
            work_center_id: null,
            work_center_name: null,
            work_center_number: null,
            work_center_description: null,
            work_center_status: 'Active',
            work_center_group: null,
            work_center_parent_group_id: null,
            work_center_parent_group_number: null,
            work_center_plant_id: null,
            work_center_plant_name: null,
            work_center_plant_code: null,
            work_center_machines: [],
            work_center_employees: [],
            work_center_date_added: null,
            work_center_timestamp: null,
            work_center_created_by_id: null,
            work_center_created_by_name: null,
            work_center_groups: [],
            loading: false,
            loading_work_center_groups: false,
            loading_machines: false,
            loading_employees: false,
            machines: [],
            employees: [],
            search: '',
        }
    },
    store,
    computed: mapState({
         //This is where data is retrieved from the Vuex state
        newWorkCenterHeader: state => state.newWorkCenterHeader,
        machineSelectionHeaders: state => state.machineSelectionHeaders,
        employeeSelectionHeaders: state => state.employeeSelectionHeaders,
    }),
    created(){
        //Captures user that created the sales order
        this.work_center_created_by_id = firebase.auth().currentUser.uid
            db.collection('users').where('uid', '==', this.work_center_created_by_id).get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
            this.work_center_created_by_name = doc.data().displayName
            })
        }),
        //Get Work Center Groups from DB
        this.loading_work_center_groups = true
        db.collection('work_center_groups').where('work_center_group_status', '==', 'Active').get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
              const data = doc.data()
              this.work_center_groups.push(data)
              this.loading_work_center_groups = false
            })
          })
        //Get Work Center Group counter from database
        db.collection('work_center_counter')
        .doc('XgDasgEKoD393P0hHc6x').get().then(snapshot => {
            // Gets corder and quote counter object from datatbase
            const count = snapshot.data().work_center_count
            if(count < 10){
                this.work_center_number = `WKC-000${count}`  
            }else if (count >= 10 && count < 100){
                this.work_center_number = `WKC-00${count}`
            }else if (count >= 100 && count < 1000){
                this.work_center_number = `WKC-0${count}`
            }else {
                this.work_center_number = `WKC-${count}`
            }        
        })
        //Get machines from DB
        this.loading_machines = true
        db.collection('machines').where('machine_status', '==', 'Active').where('machine_work_center_id', '==', null).get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
              const data = doc.data()
              this.machines.push(data)
              this.loading_machines = false
            })
          })
        //Get employees from DB
        this.loading_employees = true
        db.collection('employees').where('employee_status', '==', 'Employed').get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
              const data = doc.data()
              this.employees.push(data)
              this.loading_employees = false
            })
          })
    },
    methods: {
        //Reroute to work centers table page after database has been updated
        routeToTable(){
            this.loading_update = false
            showSnackbar("Work center updated successfully");
            this.$router.push('/plants-work-centers')
        },
         //Increment Work Center number counter
        increaseWorkCenterCount(){
            const db = firebase.firestore()
            const increment = firebase.firestore.FieldValue.increment(1);
            const workCenterNumberCount = db.collection('work_center_counter').doc('XgDasgEKoD393P0hHc6x');
            //Increments work center counter
            workCenterNumberCount.update({ work_center_count: increment });
        },
        saveWorkCenter () {
            //Form validation --> ensures required fields aren't blank before continuing further           
            if(this.$refs.form.validate()){
                this.loading = true
                //Sets the category_id == to the document id in database
                var newDocRef = db.collection('work_centers').doc();
                newDocRef.set({
                    //Populated by the v-model values in the form
                    work_center_id: newDocRef.id,
                    work_center_name: this.work_center_name,
                    work_center_number: this.work_center_number,
                    work_center_description: this.work_center_description,
                    work_center_status: this.work_center_status,
                    work_center_group: this.work_center_group,
                    work_center_parent_group_number: this.work_center_parent_group_number,
                    work_center_parent_group_id: this.work_center_parent_group_id,
                    work_center_plant_id: this.work_center_plant_id,
                    work_center_plant_name: this.work_center_plant_name,
                    work_center_plant_code: this.work_center_plant_code,
                    work_center_machines: this.work_center_machines,
                    work_center_employees: this.work_center_employees,
                    work_center_date_added: new Date().toISOString().slice(0, 10),
                    work_center_timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                    work_center_created_by_id: this.work_center_created_by_id,
                    work_center_created_by_name: this.work_center_created_by_name,
                })
                .then(() => {
                    //Increment Work Center number counter
                    this.increaseWorkCenterCount()
                    //Reroute to work centers table page after database has been updated
                    this.routeToTable()
                })
            }
        },
        //  This function is called on a hidden form field that allows us to retrieve and assign
        //  work center group data
        getWorkCenterGroupDetails(){
            db.collection('work_center_groups').where('work_center_group_name', '==', this.work_center_group).get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
              const data = doc.data()
               this.work_center_group = data.work_center_group_name
               this.work_center_parent_group_id = data.work_center_group_id
               this.work_center_parent_group_number = data.work_center_group_number
               this.work_center_plant_id = data.work_center_group_plant_id
               this.work_center_plant_name = data.work_center_group_plant
               this.work_center_plant_code = data.work_center_group_plant_code
            })
          })
        },
    }
}
</script>