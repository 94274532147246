var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-row',{staticClass:"mb-8"},[_c('v-col',{attrs:{"lg":"12"}},[_c('v-card-title',[_c('h2',[_vm._v("Work Centers")]),(_vm.firstLoad)?_c('v-skeleton-loader',{staticClass:"ml-10",attrs:{"loading":_vm.loading_user,"type":"button"}}):_vm._e(),(!_vm.firstLoad)?_c('NewWorkCenterButton'):_vm._e(),_c('v-spacer')],1)],1)],1),_c('v-sheet',{attrs:{"elevation":"4"}},[_c('v-tabs',{attrs:{"background-color":"#6666cf","center-active":"","dark":""}},[_c('v-tab',[_vm._v("Work Centers")]),_c('v-tab',[_vm._v("Work Center Groups")]),_c('v-tab',[_vm._v("Plants")]),_c('v-tab-item',[(_vm.firstLoad)?_c('v-row',[_c('v-col',[_c('v-sheet',{attrs:{"elevation":"4"}},[_c('v-skeleton-loader',{attrs:{"loading":_vm.loading_user,"type":"table","max-height":"290"}})],1)],1)],1):_vm._e(),(!_vm.firstLoad)?_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(this.user_roles_list.flat().includes('work_orders_read')),expression:"this.user_roles_list.flat().includes('work_orders_read')"}]},[_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v(" Work Centers"),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.workCenterHeaders,"items":_vm.work_centers,"search":_vm.search,"items-per-page":100},scopedSlots:_vm._u([(this.user_roles_list.flat().includes('work_orders_write'))?{key:"item.work_center_id",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"to":{ name: 'edit-work-center', params: { work_center_id: item.work_center_id }},"color":"#3d2cdd","icon":"","small":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}:null,{key:"item.work_center_number",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'view-work-center', params: { work_center_id: item.work_center_id }}}},[_vm._v(" "+_vm._s(item.work_center_number)+" ")])]}}],null,true)})],1)],1)],1):_vm._e()],1),_c('v-tab-item',[(_vm.firstLoad)?_c('v-row',[_c('v-col',[_c('v-sheet',{attrs:{"elevation":"4"}},[_c('v-skeleton-loader',{attrs:{"loading":_vm.loading_user,"type":"table","max-height":"290"}})],1)],1)],1):_vm._e(),(!_vm.firstLoad)?_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(this.user_roles_list.flat().includes('work_orders_read')),expression:"this.user_roles_list.flat().includes('work_orders_read')"}]},[_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v(" Work Center Groups"),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.workCenterGroupHeaders,"items":_vm.filteredWorkCenterGroups,"search":_vm.search,"items-per-page":100},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"lg":"3","md":"3"}},[_c('v-select',{attrs:{"items":['Active','Inactive'],"label":"Status","outlined":"","flat":"","solo":"","dense":"","append-icon":"mdi-filter-variant"},model:{value:(_vm.workCenterGroupStatus),callback:function ($$v) {_vm.workCenterGroupStatus=$$v},expression:"workCenterGroupStatus"}},[[_c('v-list-item',{attrs:{"slot":"prepend-item"},slot:"prepend-item"},[_c('h5',[_vm._v("Filter by Status")])])]],2)],1),_c('v-spacer')],1)],1)]},proxy:true},(this.user_roles_list.flat().includes('work_orders_write'))?{key:"item.work_center_group_id",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"to":{ name: 'edit-work-center-group', params: { work_center_group_id: item.work_center_group_id }},"color":"#3d2cdd","icon":"","small":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}:null],null,true)})],1)],1)],1):_vm._e()],1),_c('v-tab-item',[(_vm.firstLoad)?_c('v-row',[_c('v-col',[_c('v-sheet',{attrs:{"elevation":"4"}},[_c('v-skeleton-loader',{attrs:{"loading":_vm.loading_user,"type":"table","max-height":"290"}})],1)],1)],1):_vm._e(),(!_vm.firstLoad)?_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(this.user_roles_list.flat().includes('work_orders_read')),expression:"this.user_roles_list.flat().includes('work_orders_read')"}]},[_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v(" Plants"),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.plantHeaders,"items":_vm.filteredPlants,"search":_vm.search,"items-per-page":100},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"lg":"3","md":"3"}},[_c('v-select',{attrs:{"items":['Active','Inactive'],"label":"Status","outlined":"","flat":"","solo":"","dense":"","append-icon":"mdi-filter-variant"},model:{value:(_vm.plantStatus),callback:function ($$v) {_vm.plantStatus=$$v},expression:"plantStatus"}},[[_c('v-list-item',{attrs:{"slot":"prepend-item"},slot:"prepend-item"},[_c('h5',[_vm._v("Filter by Status")])])]],2)],1),_c('v-spacer')],1)],1)]},proxy:true},(this.user_roles_list.flat().includes('work_orders_write'))?{key:"item.plant_id",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"to":{ name: 'edit-plant', params: { plant_id: item.plant_id }},"color":"#3d2cdd","icon":"","small":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}:null,{key:"item.plant_code",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'view-plant', params: { plant_id: item.plant_id }}}},[_vm._v(" "+_vm._s(item.plant_code)+" ")])]}},{key:"item.plant_description",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"truncate"},[_vm._v(_vm._s(item.plant_description))])])]}}],null,true)})],1)],1)],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }